import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {requestGetHTTP} from "../helpers/helper";

async function getCsrfToken() {
    let csrfToken = await requestGetHTTP('/csrf-token');
    return csrfToken.csrf_token;
}

window.Pusher = Pusher;
//let bearerTokenHeader = localStorage.getItem('token');

const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    forceTLS: true,
    /*auth: {
        headers: async () => {
            const csrfToken = await getCsrfToken();
            return {
                'X-CSRF-TOKEN': csrfToken,
                Authorization: `Bearer ${bearerTokenHeader}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
        },
    },
    authEndpoint: 'https://api.stag.jazzesl.com/pusher/auth'*/
});

export default echo;