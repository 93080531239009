import React, { useLayoutEffect, useState } from 'react';
import styles from '../../../assets/css_modules/redeem/redeemBody.module.css';
import RewardsJsonData from "../../data/items.json";
import { getRedeemItem } from "../../../ApiCalls/Redeem/api_v1_redeem";
import RedeemForm from "./RedeemForm";
import warningIcon from '../../../assets/images/icons/warning-icon.svg';
function RedeemBody() {
    const [rewards, setRewards] = useState(RewardsJsonData);
    const [isDisabled, setDisabled] = useState(false);
    const [insufficentMsg, setInsufficentMsg] = useState(false);
    const [freeTrialMsg, setFreeTrialMsg] = useState("");
    const [showForm, setShowForm] = useState({
        formConsentShow: false,
        formShow: false,
        resp: "",
        item: "",
        key: "",
        name: "",
        tel: "",
        uuid: "",

    });

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#191919";
        document.title = "Redeem | ESL";
    }, []);
    const handleClick = (e, propValue, name, credits) => {
        let value = propValue;
        e.target.innerHTML = "Please Wait";
        e.target.style.backgroundColor = "rgb(74, 74, 74)";
        setDisabled(true);
        let itemData = {
            "item": value
        }
        getRedeemItem(itemData).then(response => {
            // console.log(response.data.success)
            const accessToken = response.headers['access_token'];
            if (accessToken && accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.errors && response.data.data.msg === "Ko") {
                setShowForm({ ...showForm, formConsentShow: true });
                setFreeTrialMsg(response.data.data.details);
                setTimeout(() => {   // ***
                    setMessage();  // *** If you want to clear the error message as well
                    setShowForm({ ...showForm, formConsentShow: false });
                    setFreeTrialMsg("");
                }, 2500);
                return;
            }
            if (response.data.errors && response.data.data.message === "Insufficent Coins" && response.status === 200) {
                setInsufficentMsg(true);
                setTimeout(() => {   // ***
                    setMessage();  // *** If you want to clear the error message as well
                }, 1500);

            }
            else if (response.data.success && response.status === 200) {
                let resp = response.data.data;
                let item = resp.item;
                let key = resp.key;
                let name = resp.name;
                let tel = resp.tel;
                let uuid = resp.uuid;

                setShowForm({ ...showForm, formShow: true, item: item, key: key, name: name, tel: tel, uuid: uuid });

            }


        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setDisabled(false); // Ensure button state is reset
        });
        function setMessage() {
            e.target.style.backgroundColor = "#252525"; e.target.innerHTML = "Redeem"; setInsufficentMsg(false); setDisabled(false);
        }
    }
    const Card = ({ name, credits, thumbnail, id, handleClick, propId, isDisabled }) => (
        <section className={styles.redeemCard} id={id}>
            <img className={styles.cardImg} src={thumbnail} alt={name} />
            <div>
                <h1 className='text-white fs-24'>{name}</h1>
                <p className="text-white">{credits} Credits</p>
            </div>
            <div className={styles.rightSideBtn}>
                <button className={`text-white ${styles.btn}`} onClick={(e) => { handleClick(e, propId, name, credits) }} disabled={isDisabled}>
                    Redeem
                </button>
            </div>
        </section>
    );
    const renderCards = (gameData) => {
        return gameData.map((item, index) => (
            <Card
                key={index}
                id={`${item.name}-${index}`}
                name={item.name}
                credits={item.credits}
                thumbnail={item.thumbnail}
                handleClick={handleClick}
                propId={index + 1}
                isDisabled={isDisabled}
            />
        ));
    };
    return (
        <>
            {(showForm.formConsentShow && <div className="modal fade subscribe-modal" id="showWarnings" tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ opacity: "1", display: "block" }}>
                <div className="modal-dialog subscribe-modal-dialog">
                    <div className="modal-content subscribe-modal-content">
                        <div className="modal-body subscribe-modal-body">
                            <div className="subscribe-modal-body-back-img notification-back-img">
                                <img className="rewards-modal-icon" src={warningIcon} alt="warning-icon" />
                            </div>
                            <h6 className="subscribe-info-heading-modal">Warning..!!</h6>
                            <div className="subscribe-heading-modal">
                                <div dangerouslySetInnerHTML={{ __html: freeTrialMsg }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
            {(showForm.formShow) ? <RedeemForm showForm={showForm} /> :
                // (showForm.formConsentShow) ? <SubscribeConsentForm /> :
                <main className={styles.main} id="main">
                    <section className={`${styles.insufficentCoins} ` + (insufficentMsg ? `${styles.show}` : `${styles.hidden}`)}>
                        <p>Insufficent Coins</p>
                    </section>
                    {Object.keys(rewards).map((game) => (
                        <div key={game}>
                            <div className={styles.cardContainer}>
                                {renderCards(rewards[game])}
                            </div>
                        </div>
                    ))}
                </main>

            }
        </>
    )
}
export default React.memo(RedeemBody);