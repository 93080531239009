import React, {useEffect, useState} from 'react';
import Loader from "../includes/Loader";
import {requestGetHTTP} from "../helpers/helper";

function UnSubscribed() {

    useEffect(() => {
        async function fetchProfileData() {
            let getProfile = JSON.parse(localStorage.getItem("profileData"));
            if(!getProfile){
                getProfile = await requestGetHTTP('/v1/user/profile');
            }
            return getProfile.phone;
        }

        fetchProfileData().then(msisdn => {
            localStorage.removeItem('token');
            if (msisdn) {
                unSubscribeEncodeData(msisdn);
            }
        });

        const unSubscribeEncodeData = async (mobile) => {
            try {
                if(mobile){
                    const domainName = 'jazz_esl';
                    const response = await fetch('https://unsubscribe.jazzesl.com.pk/api/encrypt', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ mobile, domainName }),
                    });
                    const data = await response.json();
                    if(data.status === 'success' && data.data.mobile && data.data.mobile) {
                        window.location.replace("https://unsubscribe.jazzesl.com.pk/" + data.data.mobile + "/" + data.data.domainName);
                    }
                }
            } catch (error) {
                console.error('Error fetching while unsubscribe:', error.message);
            }
        };

    }, []);

    return (
        <Loader/>
    );
}

export default UnSubscribed;
