import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../assets/css/main-slider.css';
import bannerPrimary from '../../assets/images/banner/slider_primary.avif';
// import banner1 from '../../assets/images/banner/slider1.avif';
// import banner2 from '../../assets/images/banner/slider2.avif';
// import banner3 from '../../assets/images/banner/slider3.avif';
// import banner4 from '../../assets/images/banner/slider4.avif';
// import banner5 from '../../assets/images/banner/slider5.avif';
import Slider from 'react-slick';
import Loader from "./Loader";


const HomeSlider = () => {
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        // arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
    };

    return (
        <section>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="banner-view d-flex justify-content-center">
                        <div className="banner-text">
                            <p className="banner-heading">Play Live Tournaments</p>
                        </div>
                        {/* <Slider {...settings} className="slider slider-mob"> */}
                        <div className='position-relative'>
                            <Link className="responsive-btn" to={'/tournaments'}>
                                Register Now
                            </Link>
                            <img className="slider-img responsive-img" src={bannerPrimary} alt="Banner-1" onClick={() => { navigate('/tournaments') }} />
                        </div>
                        {/* <img className="slider-img" src={banner1} alt="Banner-1" />
                            <img className="slider-img" src={banner2} alt="Banner-2" />
                            <img className="slider-img" src={banner3} alt="Banner-3" />
                            <img className="slider-img" src={banner4} alt="Banner-4" />
                            <img className="slider-img" src={banner5} alt="Banner-5" /> */}
                        {/* </Slider> */}
                    </div>
                    {/* <div className="banner-text-mobile">
                        <p className="banner-heading-mobile">Compete in Tournament</p>
                    </div> */}
                </>
            )}
        </section>
    );
}

export default HomeSlider;