import React, { useMemo, useEffect, useState } from 'react';
import { useLocation, Outlet } from "react-router-dom";
import { storeToken, removeTokens, getToken } from "../auth/AuthController";
import { requestGetHTTP } from "../helpers/helper";

const useAuth = () => {
    let isTokenAvailable = localStorage.getItem("token");
    const location = useLocation();
    const searchParams = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);
    useEffect(() => {

        async function fetchProfileData() {
            try {
                let getProfile = JSON.parse(localStorage.getItem("profileData"));
                if (!getProfile) {
                    getProfile = await requestGetHTTP('/v1/user/profile');
                }
                if (getProfile) {
                    localStorage.setItem('profileData', JSON.stringify(getProfile));
                }
            } catch (error) {
                console.error('profile data fetching ', error);
            }
        }

        /**
         * Get param for login
         */
        async function getParamsForLogin() {
            const access_code = searchParams.get("a");
            const service_name = searchParams.get("s");
            const path = searchParams.get("module");
            if (access_code) {
                const response2 = await getToken({ msisdn: access_code, s: service_name });
                if (response2.status === 200 && response2.headers?.['access_token']) {
                    removeTokens();
                    storeToken(response2.headers['access_token']);

                    fetchProfileData();
                    if (service_name === "gn" && path === "tournaments") {
                        return window.location.href = 'tournaments';
                    }
                    else if (service_name === "gn" && path === "challenges") {
                        return window.location.href = 'challenges';
                    }
                    window.location.href = '/';
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('profileData');
                }
            }
        }

        /**
         *
         * @returns {Promise<void>}
         */
        const checkAuth = async () => {
            try {
                getParamsForLogin();
                if (isTokenAvailable) {
                    requestGetHTTP('/v1/jt/dt').then(response => {
                        if (response && response.success === true) {
                            fetchProfileData();
                        } else {
                            removeTokens();
                            window.location.href = '/';
                        }
                    })
                }
            } catch (error) {
                removeTokens();
                getParamsForLogin();
                console.error('Error verify token', error);
            }
        };
        checkAuth();
    }, [isTokenAvailable, searchParams]);
}

const ProtectedRoutes = () => {
    useAuth();
    return <Outlet />;
}
export default ProtectedRoutes;