import React, { useState, useEffect, useLayoutEffect } from 'react';
import '../../assets/css/leaderboard.css';
import firstSvg from '../../assets/images/leaderboard/1.svg';
import secondSvg from '../../assets/images/leaderboard/2.svg';
import thirdSvg from '../../assets/images/leaderboard/3.svg';
import { getLeaderboard } from '../api_calls/getLeaderboard';
import Loader from '../includes/Loader';

const Leaderboard = () => {
    const [players, setPlayers] = useState([]);
    const [notFoundLeaderboard, setNotFoundLeaderboard] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useLayoutEffect(() => {
        document.title = 'LeaderBoard | Jazz Esl';
    }, [])

    useEffect(() => {
        async function fetchLeaderboardDetails() {
            try {
                const leaderboard = await getLeaderboard();
                const data = Array.isArray(leaderboard.data.data) ? leaderboard.data.data : Object.values(leaderboard.data.data);
                if (data.length > 0) {
                    setPlayers(data);
                } else {
                    setNotFoundLeaderboard(true);
                }
            } catch (error) {
                setNotFoundLeaderboard(true);
            } finally {
                setLoading(false);
            }
        }

        fetchLeaderboardDetails();
    }, []);


    const getRankIcon = (index) => {
        switch (index) {
            case 0:
                return <img src={firstSvg} alt="First Place" className="rank-icon" />;
            case 1:
                return <img src={secondSvg} alt="Second Place" className="rank-icon" />;
            case 2:
                return <img src={thirdSvg} alt="Third Place" className="rank-icon" />;
            default:
                return <span className="rank-number">{index + 1}</span>;
        }
    };

    return (

        <div className='leaderboard-main'>
            <div className='leaderboard-h1-wrap'>
                <h1 className='leaderboard-h1'>LEADERBOARD</h1>
            </div>
            {isLoading ? (
                <Loader />
            ) : notFoundLeaderboard ? <h1 className='not-loaded'>Data could not be loaded please try later</h1> : (
                <ul className='leaderboard-list'>
                    {players.map((player, index) => (
                        <li key={index} className='leaderboard-item'>
                            <div className='rank'>{getRankIcon(index)}</div>
                            <div className='rank-info'>
                                <div className='name'>{player.name}</div>
                                <div className='score'>{player.score}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Leaderboard;
