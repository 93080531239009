import React, { useEffect, useState } from 'react';
import '../../assets/css/tournament.css';
import { requestPostHTTP } from "../helpers/helper";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Loader from "../includes/Loader";
import { isLogin } from "../auth/AuthController";
import { useSelector } from 'react-redux';

function GameChallenges() {
    const { game_id } = useParams();
    const [gameChallenges, setGameChallenges] = useState([]);
    const [notgameChallenges, setNotGameChallenges] = useState(false);
    const [loading, setLoading] = useState(true);
    const modal = useSelector((state) => state.openSubscribedModal);

    useEffect(() => {
        document.title = 'Game Challenges | Jazz Esl'; // title
        async function fetchReqData() {
            try {
                const getGameChallenges = await requestPostHTTP('/v1/challenges/game/all', { id: game_id });
                if (getGameChallenges.data) {
                    setGameChallenges(getGameChallenges.data);
                } else {
                    setNotGameChallenges(true);
                }
            } catch (error) {
                console.error('Error fetching game challenges data:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchReqData();

    }, []);
    /**
     *
     * @param event
     */
    const checkLogin = (event) => {
        if (!isLogin()) {
            event.preventDefault();
            modal.subscribedModalRef.current.click();
        }
    }

    return (
        <section>
            <div className="cards-wrapper-tournament gaming-challenges col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tournament-heading">
                    <h2 className="headingz mb-4">Daily Gaming Tasks</h2>
                    <p className="tournament-sub-heading">{gameChallenges.game && (gameChallenges.game)} Tasks</p>
                </div>

                <div className="row">
                    {loading ? (
                        <Loader />
                    ) : (
                        gameChallenges.challenges && gameChallenges.challenges.length > 0 && gameChallenges.challenges.map((gameChallenge, index) => (
                            <div key={index} className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <div className="cards-tournament-round">
                                    <div className="card-image-wrapper">
                                        <img src={gameChallenge.challengeThumbnail} alt="gameChallenge-img" />
                                    </div>
                                    <div className="tournament-card-info">
                                        <div className="row">
                                            <div className="col-8 tournament-heading-wrapper">
                                                <h6 className="tournament-info-heading fs-18i">{gameChallenge.objective}</h6>
                                                <p className="card-heading-tournament">Win {gameChallenge.prize} Credits</p>
                                            </div>
                                            {/* 0 means make new screen shot 2 means previous screen shoor rejected add new one */}
                                            {(gameChallenge.makeEntry === 0 || gameChallenge.makeEntry === 2) ?
                                                <div className="col-4 pl-0">
                                                    <NavLink onClick={checkLogin} className="play-btn w-100i h-50i p-0i text-center" to={`/submit-entry/${game_id}/${gameChallenge.challengeId}/${gameChallenge.makeEntry}`}>
                                                        Submit Entry
                                                    </NavLink>
                                                </div>
                                                : (<div className="col-4 pl-0">
                                                    <NavLink onClick={checkLogin} className="play-btn w-100i h-50i p-0i text-center"
                                                        to={`/entry-details/${game_id}/${gameChallenge.challengeId}`}>
                                                        View Details
                                                    </NavLink>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    {notgameChallenges && (
                        <div className="d-flex justify-content-center align-items-center no-data-found">
                            <p>No game daily tasks found</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default GameChallenges;